<template>
<div class='page'>
 
 <industrialbanner :IndustrialbannerData="IndustrialbannerData"></industrialbanner>
  <myhead :hbg="hbg"></myhead>
  <bread :bbg="bbg"></bread> 
 <industrieshead :industriesheadLists="industriesheadLists"></industrieshead>
   <applications2 :ApplicationsLists="ApplicationsLists"></applications2>
    <capabilities :CapabilitiesList="CapabilitiesList"></capabilities>
  <customers></customers>
 
</div>
</template>
<script>
import bread from '../../components/bread.vue'
import industrialbanner from '../../components/Industrialbanner.vue'
import myhead from "../../components/myHead.vue";
import industrieshead from "../../components/industrieshead.vue";
import applications2 from "../../components/Applications2.vue";
import customers from "../../components/Customers.vue"
import capabilities from "../../components/Capabilities.vue"

export default {
    components: {
    myhead,
    industrieshead,
    applications2,
    customers,
    capabilities,
    industrialbanner,
    bread,
  },
  data() {
    return {
           CapabilitiesList:{
        H1:'Robotics & Automation Post-Processing Capabilities',
        lists:[{ 
            title:'Anodizing',
        },{
            title:'Passivation',
        },{
            title:'Electroplating',
        },{
            title:'Powder Coating',
        },{
            title:'Insert Installation',
        },{
            title:'Laser Cutting',
        },],
        img: require('../../assets/img/roboticsAutomationcal1.png')
    },
      IndustrialbannerData:{
				H1:'Robotics & Automation',
				info:'Need some assistance bringing your robotic device or parts from the sketch-board to reality?',
				background:'#F2F3F5',
        color:'#333333',
				img:require('../../assets/img/roboticsAutomationbanner.png'), 
		
			},
          hbg:{
        bg:'#ffffff'

    },
    bbg:{
 bg:'#ffffff',
 color:'#000000'
    },

      industriesheadLists: {
        ref: "Robotics & Automation",
        H1: "ROBOTICS & AUTOMATION",
        info: `Robotics and automation are two of the most emerging industries as the world witnesses new innovations in the fields every day. Due to the cost reduction and time saving rapid prototyping has become such a coveted technology among all industries. In order to give clients the necessary customization and hands on feel, the Robotics and automation industries were quick to adopt rapid prototyping. It gives clients lots of control, as they can customize their desired products in any way they want. Besides, the process becomes a lot less expensive and less time consuming.`,
        imgs: [
          {
            src: require("../../assets/img/roboticsAutomation1.png")
          }
        ],
        h2: "Top Robotics & Automation Applications",
        data: [
          {
            content:  "Function prototypes of various types of robots "
          },
          {
            content: "lastic or metal housings and fasciae"
          },
          {
            content: "structural components of automation products"
          },
          {
            content: "Lot Automation Products"
          }
        ]
      },
      ApplicationsLists: {
        H1: "WHY ROBOTICS COMPANIES CHOOSE HLH ?",
        data: [
          {
            img: require("../../assets/img/aerospaceUAV1model3.png"),
            title: "Low-Volume Production",
            info: "Get low-volume production parts in as fast as 2 day to streamline your supply chain once before and after products are launched to market."
          },
          {
            img: require("../../assets/img/jqr01.png"),
            title: "Interactive Design Analysis",
            info: "Make critical design adjustments that save development time and cost with design for manufacturability feedback on every quote."
          },
          {
            img: require("../../assets/img/aerospaceUAV1model2.png"),
            title: "Industry Leading Quality",
            info: "Quality services include material certifications, certificates of conformity, advanced inspection reports and more."
          }
        ]
      }
    };
  },
  
  methods: {},
  mounted() {},
  created() {},
  props: {},
  watch: {},

};
</script>
<style scoped lang="scss">
.page {
}
.bannerBg {
  width: 100%;
  height: 620px;
  background: red;
}
 
</style>